export default {
  apiGateway: {
    REGION: "ap-southeast-1",
    URL: "https://pl1kf3daji.execute-api.ap-southeast-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "ap-southeast-1",
    USER_POOL_ID: "ap-southeast-1_bMoDi5q4B",
    APP_CLIENT_ID: "1ptcmebhtqq758a5blljuovo61",
    IDENTITY_POOL_ID: "ap-southeast-1:449cddfe-6887-424e-8b1d-232aae470ba5"
  },
  cognito_old: {
    REGION: "ap-southeast-1",
    USER_POOL_ID: "ap-southeast-1_zVXAzTYs2",
    APP_CLIENT_ID: "6235ubr103q3cj4fbab1r9gp12",
    IDENTITY_POOL_ID: "ap-southeast-1:0979d679-c1e1-4360-b187-8ceb859dc1ff"
  }
};
