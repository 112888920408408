import * as React from 'react';
import { useState } from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    useNotify,
    useRecordContext,
    PasswordInput,
    email,
    ImageInput,
    ImageField,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';


const StoreCreate = () => {
  const notify = useNotify();
  const [fileCount, setFileCount] = useState<number>(0);
  const maxFileNum = 3;

  return <Create sx={{ maxWidth: 500 }}>
      <SimpleForm
          sx={{ maxWidth: 500 }}
          // Here for the GQL provider
          defaultValues={{
            name: 'test-store',
            desc: 'test store desc',
            contact_info: 'mail2@gmai.com'
          }}
          validate={validateForm}
      >
          <TextInput source="store_name" isRequired fullWidth />
          <TextInput source="desc"  fullWidth />
          <TextInput source="contact_info"  fullWidth />
          <Separator />
          <ImageInput source="medias" label="store pictures" accept="image/*"
            multiple={true}
            onRemove={ (e:any)=>{ setFileCount(fileCount-1) } }
            placeholder={
              <Box
                sx={{
                    borderWidth: 2,
                    borderStyle: 'dashed',
                    // backgroundColor: '#f8f8f8',
                    borderColor: 'grey.500',
                    borderRadius: '8px',
                    height: 60,
                }}
                ><br/>Drop your file here
              </Box>
            }
            options={{
              multiple: true,
              maxFiles: maxFileNum,
              maxSize: 2000000,
              useFsAccessApi: true,
              validator:(f:any)=>{
                console.log("fileCount:", fileCount)
                if(fileCount >= maxFileNum){
                  console.log("> maxFileNum ", maxFileNum)
                  notify('maxImages')
                  return {
                    code: "exceeded-max-file-num",
                    message: `Exceed max file number`
                  };
                }
                return null;
              },
              onDrop:(acceptedFiles)=>{
                  // console.log(acceptedFiles.length)
                  // notify('....')
                  console.log(fileCount)
                  setFileCount(fileCount+acceptedFiles.length);

              },
              onDropRejected:(fr)=>{
                  console.log(fr)
              }
            }}
            >
              <ImageField source="src" title="title" sx={{
                '& .RaImageField-image': { maxHeight: 80, minHeight: 80, }
               }}/>
          </ImageInput>

          <Separator />

      </SimpleForm>
  </Create>

}


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {

    const errors = {} as any;
    //
    // if (!values.email) {
    //     errors.email = 'ra.validation.required';
    // } else {
    //     const error = email()(values.email);
    //     if (error) {
    //         errors.email = error;
    //     }
    // }
    // if (values.password && values.password !== values.confirm_password) {
    //     errors.confirm_password =
    //         'resources.customers.errors.password_mismatch';
    // }
    // console.dir(errors)
    return errors;
};

// <ImageField source="src" title="title" sx={{ maxHeight: 120, height:120 }}/>

const CssImageField = styled(ImageField)({
  // 'input[type=number]::-webkit-inner-spin-button': {
  //   // '-webkit-appearance': 'none',
  //   MozAppearance: 'none',
  //   WebkitAppearance: 'none',
  //   margin: 0,
  // },
  '& image': { // This will override the style of the <img> inside the <div>
      width: 50,
      height: 50,
      maxHeight: 100,
  }

});

const Separator = () => <Box pt="1em" />;

export default StoreCreate;
