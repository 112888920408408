// import InvoiceIcon from '@mui/icons-material/LibraryBooks';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import AddReactionIcon from '@mui/icons-material/AddReaction';

import {CocoLineCreate} from './CocoLineCreate';
import {CocoLineEdit}   from './CocoLineEdit'
import {CocoLineList}   from './CocoLineList'

export default {
  create: CocoLineCreate,
  edit:   CocoLineEdit,
  list:   CocoLineList,
  icon:   AddReactionIcon,
};
