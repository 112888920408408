import {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext
} from "react";

import {
  TextInput,
  NumberInput,
  SimpleForm,
  Toolbar,
  SaveButton,
  SaveContextProvider,
  useNotify,
  useDataProvider,
  useGetIdentity
} from "react-admin";

import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices
} from 'react-admin';


import {SingleImageInput} from './utils'
import {Box, Typography, styled} from '@mui/material'

export const ProfileContext = createContext({
    profileVersion: 1.0,
    refreshProfile: ()=>{}
});

export const ProfileProvider = ({ children }:any) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () => {
        setProfileVersion((currentVersion) => currentVersion + 1)
      }
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

export const ProfileEdit = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [saving, setSaving] = useState(false);
  const { refreshProfile } = useProfile();

  const { isLoading, identity } = useGetIdentity();
  console.log(identity)

  const handleSave = useCallback(
    async (values) => {
      setSaving(true);
      const result = await dataProvider.updateUserProfile(
        { data: values }
      );

      console.log({result})

      if(result.data){
        setSaving(false);
        notify("Your profile has been updated", {type:"info"});
        refreshProfile();
      }
      else{
        setSaving(false);
        notify(
          "A technical error occured while updating your profile. Please try later.",  {type:"warning"}
        );
      }

    },
    [dataProvider, notify, refreshProfile]
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving
    }),
    [saving, handleSave]
  );

  if (isLoading) {
    return null;
  }

  return (
    <SaveContextProvider value={saveContext}>
      {
        isLoading? <div> loading... </div>:
        <SimpleForm onSubmit={handleSave} record={identity ? identity : {}}
          toolbar={<ProfileEditToolbar />}
        >
          {
            isSafari()&&(
              <Typography sx={{paddingY: '10px'}} fontSize='14px' color='indianred'>
                This page may not work properly in Safari, prefer Google Chrome <br/>
              </Typography>
            )
          }

          <Typography variant='h1' sx={{fontSize: '20px'}} py='10px'>
            Account Settings:
          </Typography>
          <TextInput source="givenName"   validate={required()} label="Your name" />
          <TextInput source="phoneNumber" validate={required()} label="Phone Number" disabled />
          <SingleImageInput source="avatarFile" label="Avatar picture" />
          <Box pt="1em" />
          <Typography variant='h1' sx={{fontSize: '20px'}} py='10px'>
            CocoLine Templates:
          </Typography>
          <TextInput source="groupName" fullWidth label="Group name" />
          <TextInput source="groupDesc" fullWidth multiline={true} maxRows={4} minRows={3}/>
          <TextInput source="collectionLoc"  validate={validatePostCode}
            sx={{width:'400px'}} label="Postal Code (Default Collection Location)"/>
        </SimpleForm>
      }

    </SaveContextProvider>
  );
};

const validatePostCode = [required(), minLength(6), maxLength(6), number()];

const ProfileEditToolbar = (props:any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const isSafari = ()=>{
  var _isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') == -1 &&
               navigator.userAgent.indexOf('FxiOS') == -1;
  return _isSafari
}
