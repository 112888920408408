import { useState, useEffect } from 'react';
import * as React from 'react';
import {
    TextInput, SelectInput, AutocompleteInput, DateTimeInput, ImageInput,
    NumberInput, BooleanInput, List,
    ImageField, FormDataConsumer,
    useNotify,
    useEditContext,
    useRecordContext,
    useGetManyReference,
    useGetIdentity,
    useCreate,
    useCreateSuggestionContext,
    maxValue,
    minValue,
} from 'react-admin';
import {useController} from 'react-hook-form'
import { Box,  Stack , Link, Container,
    Button, IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Typography,
    Snackbar,
    Alert
} from '@mui/material';
import { DataGrid, GridColumns, GridRowsProp, GridRowModel, GridCell, GridCellProps} from '@mui/x-data-grid';

import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { SingleImageInput, MultiImageInput } from '../utils'
import { SelectWhatsAppGroup } from './SelectWhatsAppGroup'
import { CocoSlotTypeConfig, CocoSlotType, multiNumberKeys } from './CocoLineDef'

const Separator = () => <Box pt="1em" />;

export const CocoLineFields = (props: any)=>{

  const {enableEdit} = props

  const notify = useNotify();
  const maxGroupNumber = 3;

  const cutoff_date = new Date();
  cutoff_date.setDate(cutoff_date.getDate() + 1);

  const {record} = useEditContext();
  // console.log(record)

  return (
    <Box>
      <Stack direction="row" minWidth={200} width={400} spacing={2} alignItems='start'>
        <TextInput source="title" disabled={!enableEdit} required fullWidth/>
      </Stack>
      {
        <SelectWhatsAppGroup type={props.type} enableEdit={enableEdit} maxGroupNumber={maxGroupNumber} />
      }
      <MultiImageInput enableEdit={enableEdit} source="medias" label="Product pictures" maxFileNum={3} />
      <TextInput source="desc" disabled={!enableEdit} required fullWidth
        multiline={true} maxRows={15} minRows={4}/>
      <br/>
      {
        <BooleanInput source='loginToPostResponse' disabled={!enableEdit} fullWidth />
      }
      <SelectSlotType enableEdit = {enableEdit}/>
      <br/>
      {
        // <DateTimeInput source="cutoff_time" disabled={!enableEdit} />
      }
      <Separator />
    </Box>
  )
}

export const CocoLineFieldsOthers = ({enableEdit}: any)=>{

  const { isLoading, identity } = useGetIdentity();

  const ec = useEditContext();

  if(ec.record && (!ec.record.host)){
    ec.record.host = identity?.avatarFile
  }
  if(ec.record && (!ec.record.groupName)){
    ec.record.groupName = identity?.groupName
  }
  if(ec.record && (!ec.record.groupDesc)){
    ec.record.groupDesc = identity?.groupDesc
  }
  if(ec.record && (!ec.record.collectionLoc)){
    ec.record.collectionLoc = identity?.collectionLoc
  }

  return (
    identity?<Container>
      <SingleImageInput source="host" label="Host avatar" enableEdit={enableEdit} >
        <ImageField source="src" sx={{
          '& .RaImageField-image': {
            width: 60, height: 60,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            overflow: 'clip',
            objectFit: 'cover',
            borderRadius: '50%'
          },
         }}/>
      </SingleImageInput>
      <TextInput source="groupName" disabled={!enableEdit} fullWidth label="Group name" />
      <TextInput source="groupDesc" disabled={!enableEdit} fullWidth multiline={true}
                  maxRows={4} minRows={3}/>
      <TextInput source="collectionLoc" disabled={!enableEdit} fullWidth
                  label="Postal Code (Default Collection Location)" />
    </Container>:<Container/>
  )

}

type MultiNumOption = {
  label:      string,
  name?:      string,
  unitPrice?: number,
  id:         string,
}
type MultiNumOptions = {
  hasUnitPrice: boolean,
  options:      MultiNumOption[],
}

const SelectSlotType = ({enableEdit}: any)=>{

  const record = useRecordContext();
  // console.log(record)

  const { isLoading, total } = useGetManyReference(
      'slots',
      {
          target: 'cocoline', // 'product_id',
          id: record.id,
          pagination: { page: 1, perPage: 25 },
          sort: { field: 'id', order: 'DESC' },
      },
      {
          enabled: !!record,
      }
  );
  if(isLoading || total === undefined){
    return (<Box>Loading .. </Box>)
  }

  return (
    <Box>
    {
      <SelectInput required sx={{}}
          fullWidth
          disabled = {total>0 || (!enableEdit)}
          label= "User Response Type"
          source="slotType.type"
          optionText="value"  optionValue="id"
          choices={Object.values(CocoSlotType).map(x=>({id:x,value:x.replace('-', ' ') }))}
          helperText= { total==0? "Define data format that user can submit" : `Can't change because ${total} response(s) already ` }
        />
    }
    {
      <FormDataConsumer>
           {
             ({ formData, ...rest }) => {
              if(!formData.slotType){
                return
              }
              if( formData.slotType.type == CocoSlotType.SimpleNumber){
                return (
                  <React.Fragment>
                    <Stack direction="row" spacing={2} alignItems='center' py='5px'>
                      <BooleanInput label="show MOQ" source="slotType.config.showMOQ"
                        disabled={!enableEdit}
                        helperText='To display "Total Order/MOQ" ' />
                      {
                        formData.slotType.config && formData.slotType.config.showMOQ &&
                          <NumberInput source="slotType.config.moq" disabled={!enableEdit}
                            helperText='Minimum Order Quantity'
                            label='MOQ'/>
                      }
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems='center' py='5px' >
                      <BooleanInput label="Unit Price" source="slotType.config.hasUnitPrice"
                        disabled={!enableEdit}
                        helperText='Show and calculate unit price' />
                      {
                        formData.slotType.config && formData.slotType.config.hasUnitPrice &&
                          <NumberInput source="slotType.config.unitPrice" prefix='$'
                            helperText='Unit Price($)' label='Unit Price($)' disabled={!enableEdit}
                            validate={[minValue(0.01)]} min={0.01}
                            />
                      }
                      {
                        formData.slotType.config && formData.slotType.config.hasUnitPrice &&
                          <NumberInput source="slotType.config.adminFee" helperText='Admin Fee($)'
                            disabled={!enableEdit} label='Admin Fee($)' required
                            validate={[minValue(0.00)]} min={0.00}
                            />
                      }
                      {
                        formData.slotType.config && formData.slotType.config.hasUnitPrice &&
                          <NumberInput source="slotType.config.deliveryFee" helperText='co-share ($)'
                            disabled={!enableEdit} label='Delivery Fee($)' required
                            defaultValue={0}
                            validate={[minValue(0.00)]} min={0.00}
                            />
                      }
                    </Stack>
                  </React.Fragment>
                )
              }
              if(formData.slotType.type == CocoSlotType.MultiNumber){
                return <MultiNumberInputs enableEdit={enableEdit} formData={formData}/>
              }
            }
           }
       </FormDataConsumer>
    }
    </Box>
  )
}

const MultiNumberInputs = ({enableEdit, formData}: any) =>{

  const record = useRecordContext();
  const multiNumberTotalMax = 15
  const [multiNumOptions, setMultiNumOptions] = useState<MultiNumOptions>(
    // {hasUnitPrice: false, options: []}
    // record.slotType.config.multiNumOptions??
    {
      hasUnitPrice: false,
      options: record.slotType?.config.multiNumOptions?.options??multiNumberKeys(
        record.slotType?.config.multiNumberTotal??0
      ).map((x:any)=>({label:x, id:x}))
    }
  );

  return (
    <Stack direction="column" spacing={2} alignItems='start' >
      {
        // <NumberInput source="slotType.config.multiNumberTotal"
        //   label='Total Options' min={2} max={10} step={1}
        //   helperText={`Option label From #1 to #${formData.slotType.config?.multiNumberTotal??'n'}`}/>
      }
      <Stack direction="row" spacing={2} alignItems='center' >
        <SelectInput
          source="slotType.config.multiNumberTotal"
          choices={
            Array.from(Array(multiNumberTotalMax).keys()).map(
              x=>({'id':x+1, 'name':`${x+1}`})
            )
          }
          label='Total Options'
          disabled={!enableEdit}
          onChange={(e:any)=>{
              console.log(e)
              const optionNum = e.target.value
              let options: MultiNumOption[] = multiNumberKeys(optionNum).map(
                (x:any)=>({label:x, id:x} )
              )
              console.log(options)
              if(multiNumOptions.options.length>optionNum){
                options = multiNumOptions.options.slice(0, optionNum)
              }
              else{
                options = multiNumOptions.options.concat(
                  options.slice(multiNumOptions.options.length)
                )
              }
              console.log(options)
              setMultiNumOptions({...multiNumOptions, options: options })
            }}
          helperText={`S/N From #1 to #${formData.slotType.config?.multiNumberTotal??'n'}`}
        />
        {
          <NumberInput source="slotType.config.adminFee" helperText='Admin Fee ($)'
            disabled={!enableEdit} label='Admin Fee($)' required
            validate={[minValue(0.00)]} min={0.00}
            />
        }
        {
          <NumberInput source="slotType.config.deliveryFee" helperText='co-share ($)'
            disabled={!enableEdit} label='Delivery Fee($)' required
            defaultValue={0}
            validate={[minValue(0.00)]} min={0.00}
            />
        }
      </Stack>
      {
        <OptionsInput enableEdit={enableEdit} multiNumOptions ={multiNumOptions}
          setMultiNumOptions={setMultiNumOptions}/>
      }
    </Stack>
  )
}

const OptionsInput = ({
  multiNumOptions,
  setMultiNumOptions,
  enableEdit,
}: {
  multiNumOptions: MultiNumOptions,
  setMultiNumOptions: any,
  enableEdit:boolean
})=>{

  const { field } = useController({ name: 'slotType.config.multiNumOptions' });

  const { record, isLoading } = useEditContext();
  if (isLoading) return null;

  const columns: GridColumns = [
    { field: 'label', headerName: 'S/N', width: 80, editable: false },
    { field: 'name',  headerName: 'Option Name', width: 200, editable: enableEdit },
    { field: 'unitPrice',  headerName: 'Unit Price($)', width: 100, type: 'number', editable: enableEdit,
      valueParser: (value: any, params: any) => {
        return value< 0 ? 0 : value ;
      },
      renderCell: (params)=>{
        return `$${params.value}`
      }
    },
  ];

  const processRowUpdate = async (newRow: GridRowModel) => {

    const options = multiNumOptions.options.map((option)=>{
      return option.label == newRow.label ? newRow: option;
    })
    setMultiNumOptions({...multiNumOptions, options: options })
    field.onChange({...multiNumOptions, options: options, hasUnitPrice: true })
    return newRow
  }

  const handleProcessRowUpdateError = (error: Error) => {
     // setSnackbar({ children: error.message, severity: 'error' });
    console.log(error.message)
  }

  const CustomCell = (props: GridCellProps) => {
    const { children,  ...other } = props;

    const placeHolders: {[key:string]: any} = {
      'name':  <Typography fontSize='14px' color='slategray' >
                  {`Option${props.rowId.toString().replace('#',' ')}`}
                </Typography>,
      'unitPrice':   <Typography fontSize='14px' color='slategray' >$xx</Typography>,
    }
    return (
      <GridCell {...other}>
        {
          other.cellMode === 'edit' || (props.value != null && props.value !== '')
          ? children
          : ((props.field in placeHolders)?placeHolders[props.field]:'..')
        }
      </GridCell>
    );
  }

  return (
    <Box height='320px' minWidth='400px'>
      <DataGrid
        rows={multiNumOptions?.options??[]}
        columns={columns}
        components={{ Cell: CustomCell }}
        experimentalFeatures={{ newEditingApi: true }}
        pageSize={10}
        rowsPerPageOptions={[10]}
        editMode='row'
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
    </Box>
  )
}

export const transformRecord = (data: any) => {

  console.log(data)
  return {
      ...data,
      host: {
        ...data.host,
        groupName: data.groupName,
        groupDesc: data.groupDesc,
        collectionLoc: data.collecitonLoc,
      },
  }
}
