import * as React from 'react';
import { useState } from 'react';
import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    ImageField,
    ImageInput,
    PasswordInput,
    SimpleForm,
    useNotify,
    useTranslate,
} from 'react-admin';
import { Grid, Box, Typography } from '@mui/material';
import { API } from "aws-amplify";

import Aside from './Aside';
import FullNameField from '../visitors/FullNameField';
import SegmentsInput from '../visitors/SegmentsInput';
import { validateForm } from './StoreCreate';

const StoreEdit = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const [fileCount, setFileCount] = useState<number>(0);
    const maxFileNum = 3;

    return (
        <Edit title={<VisitorTitle />} redirect='edit'>
            <SimpleForm validate={validateForm}>
                <>
                    <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
                        <Grid item xs={12} md={8}>
                          <TextInput source="store_name" isRequired fullWidth />
                          <TextInput source="desc"  fullWidth />
                          <TextInput source="contact_info"  fullWidth />
                          <ImageInput source="medias" label="store pictures" accept="image/*"
                            multiple={true}
                            onRemove={ (e:any)=>{ setFileCount(fileCount-1) } }
                            placeholder={
                              <Box
                                sx={{
                                    borderWidth: 2,
                                    borderStyle: 'dashed',
                                    // backgroundColor: '#f8f8f8',
                                    borderColor: 'grey.500',
                                    borderRadius: '8px',
                                    height: 60,
                                }}
                                ><br/>Drop your file here
                              </Box>
                            }
                            options={{
                              multiple: true,
                              maxFiles: maxFileNum,
                              maxSize: 2000000,
                              useFsAccessApi: true,
                              validator:(f:any)=>{
                                console.log("fileCount:", fileCount)
                                if(fileCount >= maxFileNum){
                                  console.log("> maxFileNum ", maxFileNum)
                                  notify('maxImages')
                                  return {
                                    code: "exceeded-max-file-num",
                                    message: `Exceed max file number`
                                  };
                                }
                                return null;
                              },
                              onDrop: async (acceptedFiles)=>{
                                  // console.log(acceptedFiles.length)
                                  // notify('....')
                                  console.log(fileCount)
                                  setFileCount(fileCount+acceptedFiles.length);
                                  // console.dir(acceptedFiles)
                                  // for(const file of acceptedFiles){
                                  //   const uploaded = UploadFile(file)
                                  //   // file.src = uploaded.src
                                  // }
                              },
                              onDropRejected:(fr)=>{
                                  console.log(fr)
                              }
                            }}
                            >
                              <ImageField source="src" title="title" sx={{
                                '& .RaImageField-image': { maxHeight: 80, minHeight: 80, }
                               }}/>
                          </ImageInput>
                        </Grid>
                        <Grid item xs={12} md={4}>
                        </Grid>
                    </Grid>
                </>
            </SimpleForm>
        </Edit>
    );
};

const UploadFile = async (file: any)=>{
  const path = '/?contentType='+ file.type

  const response = await API.get('coco-s3-upload', path, {} ); //.then(async(response:{[id:string]: any}) =>{

  console.log(response);

  const formData = new FormData();
  formData.append("Content-Type", file.type);
  Object.entries(response.uploadURL.fields).forEach(([k, v]) => {
    console.log( `${k} -- ${v}`)
    formData.append(k, v as string);
  });
  formData.append("file", file);
  console.dir(formData)
  // new_medias.push({src:response.uploadURL.url+'/'+response.uploadURL.fields.key})

  const resFetch = await fetch(response.uploadURL.url, {
    method: "POST",
    body: formData,
  });
  console.dir(resFetch)
  // });

  return {src:response.uploadURL.url+'/'+response.uploadURL.fields.key};
}

const VisitorTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

export default StoreEdit;
