import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import LabelIcon from '@mui/icons-material/Label';
import SettingsIcon from '@mui/icons-material/Settings';

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    SelectInput,
    useSidebarState,
} from 'react-admin';

import visitors from '../visitors';
import orders from '../orders';
import invoices from '../invoices';
// import products from '../products';
// import categories from '../categories';
import reviews from '../reviews';
import lines from '../coco_line';
import SubMenu from './SubMenu';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {//<DashboardMenuItem />
            }
            <MenuItemLink
                to="/lines"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.lines.name`, {
                    smart_count: 2,
                })}
                leftIcon={<lines.icon />}
                dense={dense}
            />
            <MenuItemLink
                to="/my-profile"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.settings.name`, {
                    smart_count: 2,
                })}
                leftIcon={<SettingsIcon />}
                dense={dense}
            />
            {
              // <MenuItemLink
              //     to="/stores"
              //     state={{ _scrollToTop: true }}
              //     primaryText={translate(`resources.stores.name`, {
              //         smart_count: 2,
              //     })}
              //     leftIcon={<reviews.icon />}
              //     dense={dense}
              // />
            }
        </Box>
    );
};

export default Menu;
