import React, { useState, useEffect } from 'react'
import {
    Edit, SimpleForm, TabbedForm, FormTab,
    ImageField, ReferenceManyField, TextField, FunctionField,Datagrid, Pagination,
    EditActionsProps, TopToolbar,ShowButton, Toolbar,
    CreateButton, CloneButton, SaveButton, DeleteButton, DeleteWithConfirmButton, useDelete,
    useNotify,
    useGetManyReference,
    useTranslate,
    useEditContext,
    useUpdate,
    useRecordContext,
    useResourceContext, } from 'react-admin';
import { styled }         from '@mui/material/styles';
import {
      Button, Switch, Typography, Chip, Stack, Box, Popover,
      FormControlLabel }  from '@mui/material'
import ShareIcon          from '@mui/icons-material/Share';
import PlayArrowIcon      from '@mui/icons-material/PlayArrow';
import StopIcon           from '@mui/icons-material/Stop';
import LinkIcon           from '@mui/icons-material/Link';
import {ContentCopy}      from '@mui/icons-material';
import { useSnackbar }    from 'notistack';
import {useConfirm }      from 'material-ui-confirm';

import {
  CocoLineFields,
  CocoLineFieldsOthers,
  transformRecord
} from './CocoLineFields'

import {
  CocoSlotTypeConfig,
  CocoSlotType,
  multiNumberKeys,
} from './CocoLineDef'
import { FormTabForSlots }  from './CocoLineEditSlotsTab'
import {copyTextToClipboard} from '../utils'

const PageTitle = (props: any) => {
    const ec = useEditContext();
    return <span>{ec.record?.title??"..."} </span>
};

const MultiNumberField = ({source, label, fieldId}: any) => {
    const record = useRecordContext();
    return <span>{record.value.data[fieldId]}</span>;
}

interface EnableEditSwitch{
  enableEdit : boolean,
  setEnableEdit: any
}

export const CocoLineEdit = () => {

  const [enableEdit, setEnableEdit] = useState(false)

  return (
      <Edit sx={{ maxWidth: '700px', paddingBottom:'10px'}} transform={transformRecord}
            redirect='edit' title={<PageTitle/>}
            actions={<EditActions enableEdit={enableEdit} setEnableEdit={setEnableEdit} />} >
        <TabbedForm
          toolbar={<BottomToolbar enableEdit={enableEdit}/>}
          >
            <FormTab label="resources.lines.tabs.details" sx={{ maxWidth: '40em' }} >
              <CocoLineFields type='edit' enableEdit={enableEdit} />
            </FormTab>
            <FormTab label="resources.lines.tabs.others" sx={{ maxWidth: '40em' }}>
              <CocoLineFieldsOthers enableEdit={enableEdit} />
            </FormTab>
            <FormTabForSlots path="slots"/>
        </TabbedForm>
      </Edit>
  )
}

const BottomToolbar = (myProps: any) => {
    const notify = useNotify();
    const record = useRecordContext();
    console.log(record)

    const { isLoading, total, data } = useGetManyReference(
        'slots',
        {
            target: 'cocoline',
            id:     record.id,
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'create_time', order: 'ASC' },
        },
        {
            enabled: !!record,
        }
    );

    const { enableEdit, ...props } = myProps
    return (
        <Toolbar {...props}  >
          <SaveButton alwaysEnable={enableEdit}/>
          <Box flexGrow={1}/>
          {
            // <DeleteButton variant='contained' color='inherit' size='medium'
            //   disabled = {!enableEdit}
            //  />
          }
          {
            data&&(
              data.length > 0 ?
              <DeleteButton variant='contained' color='inherit' size='medium'
                disabled = {true} label='Delete Disabled (response not zero)'
               />:
               <DeleteWithConfirmButton
                 confirmTitle="Delete Line "
                 confirmContent='Are you sure you want to delete this item? '
                 variant='contained' color='inherit' size='medium'  disabled = {!enableEdit} />
             )
          }

        </Toolbar>
    );
};

const EditActions = ({ hasShow, enableEdit, setEnableEdit }: EditActionsProps & EnableEditSwitch  ) => {

  const {record, saving}                = useEditContext()
  const [update, { isLoading, error }]  = useUpdate();
  const confirm  = useConfirm();

  // console.log(record)

  const hasResponse = true // #### TODO : set real value
  const handleEnableEdit = async () => {
    if(!enableEdit && hasResponse){
      await confirm({
          title: 'Confirm',
          description: 'May create inconsistency if response created, confirm to proceed ? '
        }).then(() => {
          setEnableEdit(!enableEdit)
        }).catch(() => { /* ... */ });
    }
    else{
      setEnableEdit(!enableEdit)
    }
  }

  const handleStartStop = async({start}: {start:boolean}) => {
    console.log(start)
    if(record.is_open != start ){
      const updatedRecord = { ...record, is_open: start };
      if( start == false )
      {
        await confirm({
            title: 'Confirm',
            description: 'After cutoff, user can\'t submit response, proceed ? '
          }).then(() => {
            update( 'lines', { id: record.id, data: updatedRecord, previousData: record } )
          }).catch(() => { /* ... */ });
      }
      else
      {
        update( 'lines', { id: record.id, data: updatedRecord, previousData: record } )
      }

    }
  }

  return (
      <TopToolbar>
        {
          <Button variant='text' sx={{paddingY:'2.5px'}}
            onClick={handleEnableEdit}>
            <Switch size='small' checked={enableEdit}/>
            Edit
          </Button>
          // <Switch size='small' checked={enableEdit}/>
          // <Android12Switch checked={enableEdit}  />
        }
        {
          (location.hostname == 'localhost')&&
          <Button variant='text' startIcon={<LinkIcon />} sx={{paddingY:'2.5px'}}
            onClick={()=>{window.open("http://localhost:3006/line/" + record.id, "lineview_local")}}>
            Local
          </Button>
        }
        <BasicPopover record={record}/>
        {
            // <Switch size='small' checked={enableEdit} />
        }
        {
          // <Chip label={record.is_open ?"ONGOING":"STOPPED"} />
        }
        {
          record&&<Button variant='text' sx={{paddingY:'2.5px'}}
            onClick={
              (e)=>{

                console.dir(e)
                handleStartStop({start: !record.is_open})
              }
            }>
            <Switch size='small' checked={record.is_open!=0}/>
            {record.is_open ?"ONGOING":"STOPPED"}
          </Button>
        }
        {
          // record&&(
          //   record.is_open ?
          //     <Button variant='text' startIcon={ <StopIcon />} sx={{paddingY:'2.5px'}}
          //       onClick={()=>{handleStartStop({start:false})}}
          //       disabled={isLoading} >
          //       Cutoff
          //     </Button>
          //     :<Button variant='text' startIcon={ <PlayArrowIcon />} sx={{paddingY:'2.5px'}}
          //       onClick={()=>{handleStartStop({start:true})}}
          //       disabled={isLoading}  >
          //       Start
          //     </Button>
          // )
        }

        {/*<CloneButton className="button-clone" /> */}
        {hasShow && <ShowButton />}
        {/* FIXME: added because react-router HashHistory cannot block navigation induced by address bar changes */}
        {
           // <CreateButton />
        }
      </TopToolbar>
  )

}

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {

    const errors = {} as any;
    console.log(values)
    if (!values.title) {
        errors.title = 'ra.validation.required';
    }
    if (!values.desc) {
        errors.desc = 'ra.validation.required';
    }
    if (!values.medias) {
        errors.desc = 'ra.validation.required';
    }
    return errors;
};

const CssImageField = styled(ImageField)({

  '& image': { // This will override the style of the <img> inside the <div>
      width: 50,
      height: 50,
      maxHeight: 100,
  }

});

const BasicPopover = ({record}: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {
          // <Button aria-describedby={id} variant="contained" onClick={handleClick}>
          //   Open Popover
          // </Button>
      }
      <Button variant='text' startIcon={<LinkIcon />} sx={{paddingY:'2.5px'}}
        onClick={handleClick}>
        Link
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {
          // <Typography sx={{ p: '5px' }}>Actions:</Typography>
        }
        <Button variant='text' startIcon={<LinkIcon />} sx={{paddingY:'3.5px'}}
          onClick={()=>{
            window.open("https://q.cocolink.cc/line/" + record.id, 'lineview')
            handleClose();
          }}>
          Open
        </Button>
        <br/>
        <Button variant='text' startIcon={<ContentCopy/>} sx={{paddingY:'3.5px'}}
          onClick={()=>{
            copyTextToClipboard("https://q.cocolink.cc/line/" + record.id,
              document.getElementById('copy-btn') );
            handleClose();
            enqueueSnackbar('Link copied', { variant: 'success' });
          }}>
          Copy Link
        </Button>
      </Popover>
    </div>
  );
}
