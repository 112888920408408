/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906

import * as React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { Amplify }      from 'aws-amplify';
import config           from './amplify-config';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "coco-admin",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "coco-s3-upload",
        endpoint: "https://gt6682twqe.execute-api.ap-southeast-1.amazonaws.com/prod",
        region: config.apiGateway.REGION
      },
      {
        name: "cmk-admin",
        endpoint: "https://pbvl0dg8zi.execute-api.ap-southeast-1.amazonaws.com/prod",
        region: config.apiGateway.REGION
      }
    ]
  }
});

ReactDOM.render(<App />, document.getElementById('root'));
