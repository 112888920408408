
export enum CocoSlotType{
  PlainText     = 'plain-text',
  SimpleNumber  = 'simple-number',
  MultiNumber   = 'multi-number',
}

export type CocoSlotTypeConfig = {
  type: CocoSlotType,
  config: {
    [id:string]: any
  }
}

export const multiNumberKeys = (multiNumberMax: number): string[] =>{
  return Array.from(Array(multiNumberMax).keys()).map(
    (x:any)=>`#${x+1}`
  )
}
