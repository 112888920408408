import { useState, useEffect } from 'react';
import {
    TextInput, SelectInput, AutocompleteInput, DateTimeInput, ImageInput,
    ImageField,
    useNotify,
    useEditContext,
    useRecordContext,
    useDataProvider,
    useGetIdentity,
    useCreate,
    useCreateSuggestionContext,
} from 'react-admin';
import {
    Box,  Stack , Link, Container,
    Button, IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Snackbar,
    Alert
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export type WhatsAppGroup = {
  invitationLink : string|null,
  is_latest:  boolean
}

export const SelectWhatsAppGroup = (props: any)=>{

  const maxGroupNumber = props.maxGroupNumber;

  const dataProvider = useDataProvider();
  const [whatsAppGroups,  setWhatsAppGroups] = useState<WhatsAppGroup[]>([]);
  const [defaultGroup,    setDefaultGroup]   = useState<WhatsAppGroup|null>();
  const [openSnackbar,    setOpenSnackbar]   = useState(false);

  const {record} = useEditContext();

  // console.log(defaultGroup)

  useEffect(()=>{
    new Promise(async()=>{
        // await dataProvider.updateUserAttributes(
        //   {'custom:whatsapp_groups': JSON.stringify([])}
        // )
        const attr_val = await dataProvider.getUserAttribute( 'custom:whatsapp_groups')
        // console.log(attr_val)

        const groups: WhatsAppGroup[] = (attr_val.data.length > 0)? JSON.parse(attr_val.data).filter(
          (x:any)=>x.invitationLink&&x.invitationLink.length>0
        ):[]
        // console.log(groups)
        // setWhatsAppGroups(groups)
        if(!record){
          setWhatsAppGroups(groups)

          const defaultGroupFound = groups.filter((x)=>x.is_latest == true)
          // console.log(defaultGroupFound)
          const theDefaultGroup = whatsAppGroups.length > 0? groups[0]: null
          setDefaultGroup(theDefaultGroup)
          return
        }

        // console.log(record)
        const found_groups = record.whatsapp_group ? groups.filter(
            (x:any)=>x.invitationLink == record.whatsapp_group.invitationLink
          ): []
        const new_groups: any[] = (found_groups.length == 0 && record?.whatsapp_group )?
            groups.concat([record.whatsapp_group])
            :groups

        // console.log(new_groups)
        setWhatsAppGroups(new_groups)

    })
  },[record])
  const rec = useRecordContext();
  // console.log(rec)

  return(
    <Stack direction="row" minWidth={250} width={400} spacing={2} alignItems='start'>
      <Box minWidth={250} width={400} >
          { 'edit' == props.type && record && record.whatsapp_group &&
            <AutocompleteInput
              // sx={{maxWidth: 350}}
              disabled={!props.enableEdit}
              source="whatsapp_group.invitationLink"
              label='WhatsApp group invititaion link)' choices={ whatsAppGroups }
              optionText="invitationLink"  optionValue="invitationLink"
              // disabled
            />
          }
          {
            'create' == props.type && whatsAppGroups.length == 0 &&
              <TextInput required sx={{maxWidth: 350}}
                  source="whatsapp_group.invitationLink"
                  label='WhatsApp group link'
                  helperText="Your WhatsApp group's invititaion link, E.g.: https://chat.whatsapp.com/xxx"
              />
          }
          {
            'create' == props.type && whatsAppGroups.length > 0 &&
            <SelectInput required sx={{maxWidth: 350}}
                source="whatsapp_group.invitationLink"
                optionText="invitationLink"  optionValue="invitationLink"
                choices={whatsAppGroups}
                value={rec.whatsapp_group}
                helperText="Your WhatsApp group's invititaion link, E.g.: https://chat.whatsapp.com/xxxx"
              >
            </SelectInput>
          }
      </Box>
      {'create'==props.type && whatsAppGroups.length > 0 &&
        <Box sx={{paddingY:'15px'}}>
          <IconButton
            onClick={() => {
                if( whatsAppGroups.length >= maxGroupNumber){
                  setOpenSnackbar(true)
                  return
                }
                const newGroup = prompt('Enter a new whatsapp invititaion link');
                console.log(newGroup)
                if(newGroup){
                  setWhatsAppGroups(whatsAppGroups.concat([
                    {invitationLink: newGroup, is_latest: true}
                  ]))
                }
            }}
          ><AddCircleOutlineIcon />
          </IconButton>
        </Box>
      }
      <Snackbar
        open={openSnackbar} autoHideDuration={3000} onClose={
        (event?: React.SyntheticEvent | Event, reason?: string) => {
          if (reason === 'clickaway') {
            return;
          }
          setOpenSnackbar(false);
        }
      } >
        <Alert  severity='error' sx={{ width: '100%' }}>
           {maxGroupNumber} whatsapp groups max
        </Alert>
      </Snackbar>
    </Stack>
  )

}

// console.log(defaultGroup)
//
// useEffect(()=>{
//   new Promise(async()=>{
//       // await dataProvider.updateUserAttributes(
//       //   {'custom:whatsapp_groups': JSON.stringify([])}
//       // )
//       const attr_val = await dataProvider.getUserAttribute( 'custom:whatsapp_groups')
//       console.log(attr_val)
//
//       const groups: WhatsAppGroup[] = (attr_val.data.length > 0)? JSON.parse(attr_val.data).filter(
//         (x:any)=>x.invitationLink&&x.invitationLink.length>0
//       ):[]
//       console.log(groups)
//       // setWhatsAppGroups(groups)
//       if(!record){
//         setWhatsAppGroups(groups)
//
//         const defaultGroupFound = groups.filter((x)=>x.is_latest == true)
//         console.log(defaultGroupFound)
//         const theDefaultGroup = whatsAppGroups.length > 0? groups[0]: null
//         setDefaultGroup(theDefaultGroup)
//         return
//       }
//
//       console.log(record)
//       const found_groups = record.whatsapp_group ? groups.filter(
//           (x:any)=>x.invitationLink == record.whatsapp_group.invitationLink
//         ): []
//       const new_groups: any[] = (found_groups.length == 0 && record?.whatsapp_group )?
//           groups.concat([record.whatsapp_group])
//           :groups
//
//       console.log(new_groups)
//       setWhatsAppGroups(new_groups)
//
//   })
// },[record])
// const rec = useRecordContext();
// console.log(rec)




// <Stack direction="row" spacing={2} alignItems='start'>
//   <Box minWidth={200} width={350}>
//       { 'edit' == props.type && record && record.whatsapp_group &&
//         <AutocompleteInput source="whatsapp_group.invitationLink"
//           label='WhatsApp group invititaion link)' choices={ whatsAppGroups }
//           optionText="invitationLink"  optionValue="invitationLink"
//           // disabled
//         />
//       }
//       {
//         'create' == props.type && whatsAppGroups.length == 0 &&
//           <TextInput source="whatsapp_group.invitationLink" required
//               label='WhatsApp group link'
//               helperText="Your WhatsApp group's invititaion link, E.g.: https://chat.whatsapp.com/xxx"
//           />
//       }
//       {
//         'create' == props.type && whatsAppGroups.length > 0 &&
//         <SelectInput required
//             source="whatsapp_group.invitationLink"
//             optionText="invitationLink"  optionValue="invitationLink"
//             choices={whatsAppGroups}
//             value={rec.whatsapp_group}
//             helperText="Your WhatsApp group's invititaion link, E.g.: https://chat.whatsapp.com/xxxx"
//           >
//         </SelectInput>
//       }
//   </Box>
//   {'create'==props.type && whatsAppGroups.length > 0 &&
//     <Box sx={{paddingY:'15px'}}>
//       <IconButton
//         onClick={() => {
//             if( whatsAppGroups.length >= maxGroupNumber){
//               setOpenSnackbar(true)
//               return
//             }
//             const newGroup = prompt('Enter a new whatsapp invititaion link');
//             console.log(newGroup)
//             if(newGroup){
//               setWhatsAppGroups(whatsAppGroups.concat([
//                 {invitationLink: newGroup, is_latest: true}
//               ]))
//             }
//         }}
//       ><AddCircleOutlineIcon />
//         <Snackbar
//           open={openSnackbar} autoHideDuration={3000} onClose={
//           (event?: React.SyntheticEvent | Event, reason?: string) => {
//             if (reason === 'clickaway') {
//               return;
//             }
//             setOpenSnackbar(false);
//           }
//         } >
//           <Alert  severity='error' sx={{ width: '100%' }}>
//              {maxGroupNumber} whatsapp groups max
//           </Alert>
//         </Snackbar>
//       </IconButton>
//     </Box>
//   }
// </Stack>
