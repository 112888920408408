import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  NumberField,
  BooleanField,
  ArrayField,
  ImageField,
  FunctionField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import { AddCommentTwoTone, AddCommentOutlined,
  DoNotDisturbAlt,
  CheckCircleOutlined, DownloadDone,Done, CommentOutlined, CommentsDisabledOutlined
}  from '@mui/icons-material';
import { Box, Button } from '@mui/material'


import { Empty } from '../stores/empty'
// import { Empty } from 'react-admin'

const CustomDescField = ( props: any) => {
  // "record" is a prop received from the Datagrid

  const record = useRecordContext();
  let str = record.desc;
  return record ? (
    <span>{str.length > 20 ?  str.slice(0, 20) + "..." : str}</span>
  ) : null;
};

export const CocoLineList = () => (
    <List empty={<Empty />} hasCreate={true} bulkActionButtons={<Box/>} >
        <Datagrid rowClick="edit" sort={{field:'create_time', order:'DESC'}}>
            {
              // <TextField source="title" />
            }
            <FunctionField label='Title' render={
                (record:any) => record.title.length > 30 ?
                    record.title.slice(0, 30) + "..." : record.title
              } />
            <BooleanField source='is_open' label='Ongoing'
              TrueIcon={CommentOutlined} FalseIcon={DoNotDisturbAlt} looseValue />
            <TextField source="slotType.type" label='Type'/>
            <FunctionField label='Response' render={
                (record:any) => <a href={`/#/lines/${record.id}/slots`}>
                  <Button>
                  {record.slots.length}
                  </Button>
                  </a>
              } />
            <DateField source="create_time" label='Created'/>
            {
              // <FunctionField label='Created' render={
              //     (record:any) => new Date(record.create_time).toDateString()
              //   } />
              // <CustomDescField source="desc" />
            }
        </Datagrid>
    </List>
);


// export default CocoProductList;
