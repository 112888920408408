import React, {useEffect} from 'react';
import { Admin, CustomRoutes, Resource, ListGuesser, Authenticated} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Route } from 'react-router';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider }  from 'material-ui-confirm';

import authProvider from './authProviderAmplify';
import dataProvider from './dataProviderAmplify';

import { Login, Layout }  from './layout';
import { SignUp }         from './account/signUp'
import { SignIn }         from './account/signIn'

import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import { lightTheme } from './layout/themes';

import visitors from './visitors';
import orders from './orders';
import invoices from './invoices';
import ClnProductCreate from './coco_products/CocoProductCreate'
import ClnProductEdit from './coco_products/CocoProductEdit'
import CocoProductList from './coco_products/CocoProductList'

import reviews from './reviews';
import dataProviderFactory from './dataProvider';
import Configuration from './configuration/Configuration';
import Segments from './segments/Segments';

import {StoreList}    from './stores/StoreList_v0'
import StoreCreate    from './stores/StoreCreate';
import StoreEdit      from './stores/StoreEdit'
import {ProfileEdit}  from "./profile";
import cocolines      from './coco_line'
import { Copyright, GlobalDebug }  from './utils'

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');

const App = () => {

    useEffect(() => {
      (process.env.NODE_ENV === "production" ||
        process.env.REACT_APP_ENV === "STAGING") &&
        GlobalDebug(false, false);
    }, []);

    return (
      <React.Fragment>
        <SnackbarProvider maxSnack={3}>
        <ConfirmProvider>
        <Admin
            title=""
            authProvider={authProvider}
            dataProvider={dataProvider}
            // dashboard={Dashboard}
            // loginPage={Login}
            loginPage={SignIn}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            theme={lightTheme}
        >
            <CustomRoutes>
                <Route key="my-profile" path="/my-profile" element={
                  <Authenticated><ProfileEdit/></Authenticated>
                }/>
            </CustomRoutes>
            <CustomRoutes noLayout>
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
            </CustomRoutes>
            {
            /*
              <Resource name="customers" {...visitors} />
              <Resource
                  name="commands"
                  {...orders}
                  options={{ label: 'Orders' }}
              />
              <Resource name="invoices" {...invoices} />
              <Resource name="products" create={ClnProductCreate} edit={ClnProductEdit} list={CocoProductList} />
              <Resource name="reviews" {...reviews} />
              // <Resource name="products" {...products} />
              // <Resource name="categories" {...categories} />
            */

            }
            <Resource name='lines' {...cocolines} />
            <Resource name="stores" list={StoreList} create={StoreCreate} edit={StoreEdit}/>
        </Admin>
        </ConfirmProvider>
        </SnackbarProvider>
        <Copyright sx={{ mt: 5, mb: 2 }} />
      </React.Fragment>
    );
};

export default App;
