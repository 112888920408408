import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { Typography, Tooltip, Chip, Box, } from '@mui/material'
import {
  LocalShippingOutlined,
  InventoryOutlined,
  MonetizationOnOutlined,
  ManageAccounts,
  LocalShipping, } from '@mui/icons-material'

import {
    Edit, SimpleForm, TabbedForm, FormTab,
    ImageField, ReferenceManyField, TextField, FunctionField,
    Datagrid, Pagination,
    useGetManyReference,
    useTranslate,
    useEditContext,
    useRecordContext,
    useResourceContext, } from 'react-admin';
import {
  CocoSlotTypeConfig, CocoSlotType, multiNumberKeys, } from './CocoLineDef'

export const FormTabForSlots = (myProps: any) => {

    const record = useRecordContext();
    const [slotTypeCfg, setSlotTypeCfg] = useState<CocoSlotTypeConfig>()
    const translate = useTranslate();
    // const [label, setLabel] = useState<string>('')

    // console.log(record)
    const { ...props } = myProps

    useEffect( ()=>{
      setSlotTypeCfg(record.slotType)
    },[record])

    const { isLoading, total, data } = useGetManyReference(
        'slots',
        {
            target: 'cocoline',
            id:     record.id,
            // pagination: { page: 1, perPage: 25 },
            sort: { field: 'create_time', order: 'ASC' },
        },
        {
            enabled: !!record,
        }
    );

    if(!total){
      return null
    }

    let order = 1
    for(const rec of data??[]){
      rec.inline_order = order++
    }

    let label = translate('resources.lines.tabs.responses');
    if (!isLoading) {
        label += ` (${total})`;
    }

    return (
      <FormTab label={label} {...props} >
        <ReferenceManyField
            reference="slots"
            target="cocoline"
            pagination={<Pagination rowsPerPageOptions={[10]}/>}
            sort={{field:'create_time', order: 'ASC'}}
        >
            <Datagrid
                bulkActionButtons={false}
                // isRowSelectable={(record:any)=>{return false}}
                sx={{
                    width: '100%',
                    '& .column-comment': {
                        maxWidth: '20em',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                }}
            >
                {
                  <FunctionField label='S/N' key='NO' render={
                    (record:any) => {
                      return record.inline_order
                    }
                  } />
                }
                <TextField source="nick_name" label='Name' />
                <FunctionField label='Mobile'
                  key='phone_num'
                  render={
                    (record:any) =>  record.phone_num?

                      <Tooltip title='Open WhatsApp '>
                        <a href={`whatsapp://send?phone=${record.phone_num.slice(1)}`} target='_blank'>
                          { // https://wa.me/6512345678
                            `${record.phone_num} ${record.phone_num_verified?'✅':''}`
                          }
                        </a>
                      </Tooltip>:
                      <Typography fontSize='13px'>Not provided</Typography>
                  }
                />
                {
                  //    Plain Text Value
                  //
                  ((!slotTypeCfg) || slotTypeCfg.type==CocoSlotType.PlainText ) &&
                  <TextField source="comment" />
                }

                {
                  //   Simple Number Value - Quality
                  //
                  slotTypeCfg&& slotTypeCfg.type==CocoSlotType.SimpleNumber &&
                  <FunctionField label={
                      <div>
                        Quantity<br/>
                        {
                          // `࿏ ${data?.reduce<number>((acc, current)=>{
                          //   return acc+ parseInt(current.value.data);
                          // }, 0)}`
                        }
                        <Chip label={`
                          ${data?.reduce<number>((acc, current)=>{
                            return acc+ parseInt(current.value.data);
                          }, 0)}
                          `}
                          sx={{paddingY:'3px'}}
                          icon={<InventoryOutlined/>}
                          size='small'/>
                      </div>
                    }
                    key='quantity'
                    render={
                      (record:any) => record.value.data
                    }
                  />
                }
                {
                  //   Simple Number Value - Total Amount
                  //
                  slotTypeCfg&&
                  slotTypeCfg.type==CocoSlotType.SimpleNumber &&
                  slotTypeCfg.config.hasUnitPrice &&
                  <FunctionField label={
                      <div>
                        Total Amount
                        <br/>
                        {
                          // `($${slotTypeCfg.config.unitPrice.toFixed(2)}/unit ${slotTypeCfg.config.adminFee?'+  admin fee':''} )`
                        }
                        <Tooltip title={
                          <Typography fontSize='13px'>
                            Unit Price: ${slotTypeCfg.config.unitPrice.toFixed(2)} <br/>
                            Admin Fee:  ${slotTypeCfg.config.adminFee?.toFixed(2)} <br/>
                            Delivery Fee:  ${slotTypeCfg.config.deliveryFee?.toFixed(2)}
                          </Typography>
                        }>
                          <Chip label={`$${slotTypeCfg.config.unitPrice.toFixed(2)} each ... `}
                            sx={{paddingY:'3px'}}
                            // icon={<MonetizationOnOutlined/>}
                            size='small'/>
                        </Tooltip>
                      </div>
                    }
                    key='quantity'
                    render={
                      (record:any) => `$ ${(
                        record.value.data * slotTypeCfg.config.unitPrice
                        + (slotTypeCfg.config.adminFee?slotTypeCfg.config.adminFee:0)
                        + (slotTypeCfg.config.deliveryFee?slotTypeCfg.config.deliveryFee:0)/total
                        ).toFixed(2)
                      }`
                    }
                  />
                }
                {
                  //    Multiple Number Value
                  //
                  slotTypeCfg&& slotTypeCfg.type==CocoSlotType.MultiNumber &&
                  slotTypeCfg.config?.multiNumOptions?.options.map( ({label, name, unitPrice}:any)=>
                  {
                    const key = label
                    const subtotal = data?.reduce<number>((acc, current)=>{
                      return acc + parseInt(current.value.data[key]);
                    }, 0)
                    return (
                      <FunctionField label={
                          <Tooltip
                            // title={`${name}\n$${unitPrice}`}
                            title={
                                <Typography fontSize='13px'>
                                  Variant : {name} <br/>
                                  Unit Price: ${unitPrice}
                                </Typography>
                            }>
                          <div>
                            {
                                // <Tooltip title={name}>
                                //   <Chip label={`${key} .`} size='small'/>
                                // </Tooltip>
                            }
                            {
                              <Typography fontSize='15px' paddingY='3px'>
                                {`${key}`}
                              </Typography>
                            }
                            {
                              // <Typography fontSize='12px' paddingY='3px'>
                              //   {`EA:$${unitPrice}`}
                              // </Typography>
                            }

                            <Chip label={`$${unitPrice}`} sx={{paddingY:'3px'}}
                              icon={<MonetizationOnOutlined/>}
                              size='small'/>
                            <Chip label={`${subtotal} `}
                              icon={<InventoryOutlined/>}
                              size='small'/>
                          </div>
                          </Tooltip>
                        }
                        key={key} render={
                          (record:any) => record.value.data[key]
                        }
                        />
                    )
                  })
                }
                {
                  //   Multi Number Value - Total Amount
                  //
                  slotTypeCfg&&
                  slotTypeCfg.type==CocoSlotType.MultiNumber &&
                  <FunctionField label={
                      <div>
                        Total
                        {
                          // `${slotTypeCfg.config.adminFee?' admin fee inc':''}`
                        }
                        {
                          slotTypeCfg.config.adminFee&&
                          <div>
                            <Tooltip title={
                              <Typography fontSize='13px'>
                                Admin Fee: ${slotTypeCfg.config.adminFee??0} <br/>
                                Delivery Fee: ${slotTypeCfg.config.deliveryFee??0}
                              </Typography>
                            }>
                              <div>
                                <Chip label={`$${slotTypeCfg.config.adminFee??0}`} sx={{paddingY:'3px'}}
                                  icon={<ManageAccounts/>}
                                  size='small'/>
                                <Chip label={`$${slotTypeCfg.config.deliveryFee??0}`} sx={{paddingY:'3px'}}
                                  icon={<LocalShipping/>}
                                  size='small'/>
                              </div>
                            </Tooltip>
                          </div>
                        }
                      </div>
                    }
                    key='quantity'
                    render={
                      (record:any) => {
                        const totalDamage = slotTypeCfg.config.multiNumOptions?.options.reduce((acc:any, current:any)=>{
                          return acc+ current.unitPrice * record.value.data[current.label]
                        }, (slotTypeCfg.config.adminFee??0) + (slotTypeCfg.config.deliveryFee??0)/(total) )
                        return `$${totalDamage}`
                      }
                    }
                  />
                }
                {
                  // //    Multiple Number Value
                  // //
                  // slotTypeCfg&& slotTypeCfg.type==CocoSlotType.MultiNumber &&
                  // multiNumberKeys(slotTypeCfg.config?.multiNumberTotal).map( (key:any)=>
                  // {
                  //   // return <MultiNumberField source='comment' label={`${key}`} key={key}
                  //   //   fieldId={key}
                  //   // />
                  //   const subtotal = data?.reduce<number>((acc, current)=>{
                  //     return acc + parseInt(current.value.data[key]);
                  //   }, 0)
                  //   return <FunctionField label={
                  //       <div>
                  //         {`${key}`}<br/>
                  //         {`࿏ ${subtotal} `}
                  //       </div>
                  //     }
                  //     key={key} render={
                  //     (record:any) => record.value.data[key]
                  //   } />
                  // })
                }
            </Datagrid>
        </ReferenceManyField>
      </FormTab>
    )

};
