import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';
import { Empty } from './empty'
// import { Empty } from 'react-admin'

export const StoreList = () => (
    <List empty={<Empty />} hasCreate={true}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <DateField source="update_time" />
            <TextField source="store_name" />
            <TextField source="desc" />
            <TextField source="time_zone" />
            <TextField source="currency" />
            <TextField source="contact_info" />
            <TextField source="url_part" />
            <TextField source="medias" />
        </Datagrid>
    </List>
);
