
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import { alpha, styled } from '@mui/material/styles';
import { Auth }         from "aws-amplify";

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSnackbar } from 'notistack';

const theme = createTheme();

enum SignupPhase{
  Init,
  SendingOTP,
  OTPSent,
  OTPVerifying,
  OTPVerified
}

interface SignupSteps{
  phase:      SignupPhase;
  phoneNum?:  string;
  user?:       any;
}

export default function SignUp() {

  const useAutoConfirm = true
  const [step, setStep] = useState<SignupSteps>({phase: SignupPhase.Init})
  const [verifyCode, setVerifyCode] = useState<string>('')
  const [phoneNum, setPhoneNum] = useState<string>('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const navigate = useNavigate();

  // const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>)  => {
    event.preventDefault();
    console.dir(event)
    const data = new FormData(event.currentTarget);
    console.dir(data)

    if( step.phase == SignupPhase.Init ){
      const inputs = {
        phone: data.get('phoneNum'),
        password: data.get('password'),
        firstName: data.get('firstName'),
        lastName: data.get('lastName'),
      }
      console.log(inputs);
      const phoneNum = ('+65' + data.get('phoneNum'))?.toString()??"";

      try {
        setStep( {phase: SignupPhase.SendingOTP, phoneNum: phoneNum} );

        const { user } = await Auth.signUp({
            username: phoneNum,
            password: data.get('password')?.toString()??"passwordless-use-sms-instead",
            attributes: {
              phone_number:   phoneNum,
              name:           phoneNum,
              given_name:     inputs.firstName,
              family_name:      inputs.lastName,
            }
        }).catch(
          async(error)=>{
            console.dir(error)
            if( error.code == "UserLambdaValidationException" ){
              throw(error.message.slice("PreSignUp failed with error".length))
            }
            throw(error)
          }
        )??{user: null }

        const userSignIn = useAutoConfirm? await Auth.signIn(phoneNum): user;
        setStep({phase: SignupPhase.OTPSent, phoneNum: phoneNum, user: userSignIn});

        console.log(user);
      } catch (error: any) {
          console.log('error signing up:', error);

          enqueueSnackbar('error signing up: ' + error, { variant: 'warning' });
          setStep({phase: SignupPhase.Init, phoneNum: phoneNum});
      }
    }
    else if( step.phase == SignupPhase.OTPSent ){

      console.log("to verify")

      const phoneNum = step.phoneNum ?? ""
      console.log({
        phoneNum: phoneNum, // data.get('phoneNum'),
        verifyCode: data.get('verifyCode'),
      })
      console.log(phoneNum)

      try
      {

        setStep({phase: SignupPhase.OTPVerifying, phoneNum: phoneNum});
        const userName = phoneNum // ('+65' + data.get('phoneNum'))?.toString()??"";
        const verifyCode = data.get('verifyCode')?.toString()??""

        // const res = await Auth.confirmSignUp(userName, verifyCode);
        // console.dir(res)
        const res = useAutoConfirm?
            await Auth.sendCustomChallengeAnswer(step.user, verifyCode):
            await Auth.confirmSignUp(userName, verifyCode);
        console.dir(res)

        // Since bug https://github.com/aws-amplify/amplify-js/issues/10097
        // Method 1:
        // Show MSG to user :
        // You have successfully verified your account, for security purposes, please sign in to access the service.
        //
        // Method 2:
        // check again
        if(useAutoConfirm){
            const authUser = await Auth.currentAuthenticatedUser();
        }

        setStep({phase: SignupPhase.OTPVerified, phoneNum: phoneNum});
        setTimeout(() => navigate('/'), 5000);
      } catch (error) {
          console.log('error confirming sign up', error);
          enqueueSnackbar(''+error, { variant: 'warning' });
          setStep({...step, phase: SignupPhase.OTPSent} )
      }
    }
  };

  function onChange(value: any) {
    console.log("Captcha value:", value);
  }

  const BtnSendOTP = (props: any) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha('yourAction');
      // Do whatever you want with the token
      console.log(token.slice(0, 20))


    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return <Button
      type="submit"
      {...props}
      fullWidth
      variant="contained"
      endIcon={<SendToMobileIcon />}
      onClick={handleReCaptchaVerify}
    >
      Send OTP
    </Button>;
    // return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ldt9CsgAAAAAEfXA5XP6Ro_G8TfIAfFFhICfSxO">
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',

            }}
          >
            <Box component="img"
              sx={{
                height: '170px',
                paddingY: '20px',
              }}
              alt="logo"
              src="/icons/banner-6.png"
            />
            <Typography component="h1" variant="h5">
              Sign up Group Host
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    disabled={step.phase!=SignupPhase.Init}
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={step.phase!=SignupPhase.Init}
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography py='0px' fontSize='14px' color='indianred' >
                    Currently only invited phone number can procees, please &nbsp;
                      <a href='https://www.facebook.com/profile.php?id=100084267871131' target='_blank'>
                        request
                      </a> if you are interested
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="phoneNum"
                      label="Phone Number"
                      name="phoneNum"
                      // defaultValue=''
                      type='number'
                      disabled={step.phase!=SignupPhase.Init}
                      value={phoneNum}
                      onChange={(e:any)=>{
                        e.preventDefault();
                        if(e.target.value.length > 8 ){
                          return
                        }
                        setPhoneNum(e.target.value)
                        console.log(e.target.value)
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                        endAdornment: <InputAdornment position="end" onClick={()=>{  console.log('...') }}>
                                        <BtnSendOTP disabled={step.phase!=SignupPhase.Init||(phoneNum.length !=8)} />
                                      </InputAdornment>
                      }}
                      sx={{
                        'input[type=number]::-webkit-inner-spin-button': {
                          // '-webkit-appearance': 'none',
                          MozAppearance: 'none',
                          WebkitAppearance: 'none',
                          margin: 0,
                        },
                      }}
                    />
                </Grid>
                { (step.phase == SignupPhase.OTPSent || step.phase == SignupPhase.OTPVerifying )&&
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="verifyCode"
                      // value={verifyCode}
                      label="Verification Code"
                      name="verifyCode"
                      type='number'
                      disabled={false}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" onClick={()=>{  console.log('...') }}>

                                      </InputAdornment>

                      }}
                      sx={{
                        'input[type=number]::-webkit-inner-spin-button': {
                          // '-webkit-appearance': 'none',
                          MozAppearance: 'none',
                          WebkitAppearance: 'none',
                          margin: 0,
                        },
                      }}
                      // onChange={e => setVerifyCode(e.target.value)}
                    />
                  </Grid>
                }
                { (step.phase == SignupPhase.OTPSent || step.phase == SignupPhase.OTPVerifying )&&
                  <Grid item xs={12}>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 0, mb: 2 }}
                    >
                      Verify
                    </Button>
                  </Grid>
                }

                { (step.phase == SignupPhase.OTPVerified )&&
                  <Grid item xs={12}>
                    <Typography component="h1" variant="h5" align='center' sx={{ mt: 0, mb: 2 }}

                    >
                      Registerd !
                    </Typography>
                  </Grid>
                }
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="#/signIn" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>

        </Container>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}


export {
  SignUp
}
