import { useEffect, useState} from 'react'
import {
    Create,
    useGetIdentity,
    useDataProvider,
    useCreateContext,
    TabbedForm, FormTab,
} from 'react-admin';
import { useNavigate }      from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { useSnackbar }      from 'notistack';
import { useConfirm }       from 'material-ui-confirm';

import {
  CocoLineFields,
  CocoLineFieldsOthers,
  transformRecord,
} from './CocoLineFields'
import {WhatsAppGroup} from './SelectWhatsAppGroup'

export const CocoLineCreate = () => {
  return (
    <Create sx={{ maxWidth: 600 }} transform={transformRecord} redirect='edit'>
      <CocoLineTabbedForm />
    </Create>
  )
}

const CocoLineTabbedForm = () =>{

  const dataProvider            = useDataProvider();
  const { isLoading, identity } = useGetIdentity();
  const { saving, save }        = useCreateContext();
  const [ whatsAppGroups, setWhatsAppGroups ] = useState<WhatsAppGroup[]>([]);
  const snackBars = useSnackbar();
  const navigate  = useNavigate();
  const confirm   = useConfirm();


  const cutoff_date = new Date();
  cutoff_date.setDate(cutoff_date.getDate() + 1);

  const defaultGroupFound = whatsAppGroups.filter(x=>x.is_latest == true)
  console.log(defaultGroupFound)

  useEffect(()=>{
    new Promise(async()=>{
        const attr_val = await dataProvider.getUserAttribute( 'custom:whatsapp_groups')
        console.log(attr_val)

        const groups = (attr_val.data.length > 0)? JSON.parse(attr_val.data):[]
        setWhatsAppGroups(groups)
    })
  },[])


  if(!isLoading && !identity?.avatarFile.src){
    new Promise(async()=>{

      await confirm({
          title: 'Redirecting to profile setup ',
          description: 'To prepare your profile before start a line',
          allowClose: false,
          cancellationButtonProps: { disabled: true}
        }).then(() => {
        }).catch(() => { /* ... */ });
        navigate('/my-profile')
    })
  }

  const handleSave = async(e:any)=>{

    const errors = validateForm(e, snackBars)
    if( Object.keys(errors).length > 0 ){
      return
    }

    const existed_groups = whatsAppGroups.filter(
        (x:any)=>x.invitationLink == e.whatsapp_group.invitationLink
      )
    const newGroups = (existed_groups.length == 0)?
        whatsAppGroups.concat([{invitationLink: e.whatsapp_group.invitationLink, is_latest: true}])
        :whatsAppGroups

    for(const group of newGroups){
      group.is_latest = group.invitationLink == e.whatsapp_group.invitationLink
    }
    console.log(newGroups)

    await dataProvider.updateUserAttributes(
      {'custom:whatsapp_groups': JSON.stringify(newGroups)}
    )

    return save!(e)
  }

  return ( (identity && save )?
    <TabbedForm record={{
      id:'123',
      // title: "9999",
      host: identity?.avatarFile,
      groupName: identity?.groupName,
      groupDesc: identity?.groupDesc,
      collectionLoc: identity?.collectionLoc,
      cutoff_time: cutoff_date,
      is_open: true,
      whatsapp_group: defaultGroupFound.length > 0 ? defaultGroupFound[0]: {
        invitationLink: '', is_latest: false
      }
    }}
      onSubmit={handleSave}
    >
        <FormTab label="resources.lines.tabs.details" sx={{ maxWidth: '40em' }}>
          <CocoLineFields type='create' enableEdit={true}/>
        </FormTab>
        <FormTab label="resources.lines.tabs.others" sx={{ maxWidth: '40em' }}>
          <CocoLineFieldsOthers enableEdit={true} />
        </FormTab>
    </TabbedForm>
    :<CircularProgress/>
  )

}

const validateForm = (
    values: Record<string, any>,
    snackBars: any
): Record<string, any> => {
    const { enqueueSnackbar, closeSnackbar } = snackBars

    const errors = {} as any;
    if (!values.title) {
        errors.title = 'ra.validation.required';
    }
    else if (!values.desc) {
        errors.desc = 'ra.validation.required';
    }
    else if (!values.medias) {
        errors.medias = 'ra.validation.required';
        enqueueSnackbar(' Image is required ')
    }
    return errors;
};
