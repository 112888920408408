import { Layout, LayoutProps } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { ProfileProvider } from "../profile";

export default (props: LayoutProps) => {

  return (
    <ProfileProvider>
      <Layout {...props} appBar={AppBar} menu={Menu} />
    </ProfileProvider>
  )
};
